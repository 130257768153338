import { SimpleQuery } from "@ollie-sports/firebase-lift";

import { Team } from "@ollie-sports/models";

export function getOwnTeamsQueryFragment(p: { accountId: string }): SimpleQuery<Team> {
  return { limit: 10000, where: [{ deletedAtMS: ["==", 0] }, { accounts: { [p.accountId]: { exists: ["==", true] } } }] };
}

export function getOwnTeamsQueryFragmentV2(p: { accountId: string }): SimpleQuery<Team> {
  return getOwnTeamsQueryFragment(p) as any;
}

// i18n certified - complete
